










































import Vue from 'vue';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ToastMessage from '@/models/ToastMessage';
import AuthService from '@/services/AuthService';

const validations = {
  user: {
    password: {
      required,
    },
    newEmail: {
      required,
      email,
    },
  },
};

export default Vue.extend({
  mixins: [validationMixin],
  validations: validations,
  components: { ValidatedInput },
  data() {
    return {
      user: {
        password: '',
        newEmail: '',
      },
      loading: false,
      message: '',
    };
  },
  props: {
    userId: {
      type: String,
    },
  },
  methods: {
    checkErrors(errors: Array<string>) {
      return errors.length == 0;
    },

    handle() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.message = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        this.loading = true;
        this.message = '';
        return AuthService.changeEmail(this.user).then(
          () => {
            this.$router.push({ name: 'Login' });
            this.$store.commit('addToastMessage', new ToastMessage('Bestätigung per Mail verschickt', 'bg-success'));
            this.$router.push({ name: 'PublicPlayroomIndex' });
          },
          (error) => {
            this.loading = false;
            this.message = error;
            this.$store.commit('addToastMessage', new ToastMessage('Fehlgeschlagen', 'bg-warning'));
          }
        );
      }
    },
  },
});
